import React from "react";
import { useState, useEffect } from "react";

import "./Project-Card-Styles.css";

export function ProjectCard({ imageUrl, title, description, link, repoName }) {
  const [lastUpdated, setLastUpdated] = useState("Loading...");

  useEffect(() => {
    const fetchLastCommit = async () => {
      try {
        const token = process.env.REACT_APP_GITHUB_TOKEN;
        const response = await fetch(
          `https://api.github.com/repos/ec20468/${repoName}/commits`,
          {
            headers: {
              Authorization: `Token ${token}`,
              "User-Agent": "https://www.ahmedhassan.co.uk",
            },
          }
        );
        const data = await response.json();
        if (response.ok && data.length > 0) {
          const commitDate = new Date(data[0].commit.committer.date);
          setLastUpdated(commitDate.toLocaleDateString());
        } else {
          setLastUpdated("No commits found");
        }
      } catch (error) {
        console.error("Error fetching commit data:", error);
        setLastUpdated("Error fetching date");
      }
    };
    fetchLastCommit();
  }, [repoName]);
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="project-card"
    >
      <div className="card-component">
        <img src={imageUrl} alt="title" className={"card-image"} />
        <h3 className="card-title">{title}</h3>
        <p className="card-desc">{description}</p>
        <p className="card-desc">Last updated: {lastUpdated}</p>
      </div>
    </a>
  );
}
