import "./App.css";
import Projects from "./components/Projects/Projects";
import Bio from "./components/bio/Bio";
import { ThemeProvider } from "styled-components";
import { theme } from "./Styles";
import FadeIn from "./components/FadeIn";
import { Header } from "./components/Header/header";
import pfp from "./Assets/pfp.png";
import { ProfileImage } from "./components/bio/Bio-styles";

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App" style={{ backgroundColor: theme.colors.background }}>
        <FadeIn>
          <Header />
          <Bio />
        </FadeIn>
        <FadeIn>
          <Projects />
        </FadeIn>
      </div>
    </ThemeProvider>
  );
}
