import { ProjectCard } from "./Project-Card";
import projectsData from "../Data/Projects.json";
import todolist from "../../Assets/todolist.png";
import weather from "../../Assets/weather.png";
import NAC from "../../Assets/NAC.png";
import FRP from "../../Assets/FRP.png";
import OracleX from "../../Assets/OracleX.png";
import Countdown from "../../Assets/countdown.png";
import Store from "../../Assets/Store.png";

const images = {
  "todolist.png": todolist,
  "weather.png": weather,
  "NAC.png": NAC,
  "FRP.png": FRP,
  "OracleX.png": OracleX,
  "countdown.png": Countdown,
  "Store.png": Store,
};

export default function Projects() {
  return (
    <div
      style={{
        display: "block",
        gap: "20px",
        justifyContent: "center",
      }}
    >
      <h1> Projects</h1>
      <div
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {projectsData.map((project, index) => (
          <ProjectCard
            key={index}
            imageUrl={images[project.imageName]}
            title={project.title}
            description={project.description}
            link={project.link}
            repoName={project.repoName}
          />
        ))}
      </div>
    </div>
  );
}
