import styled, { keyframes } from "styled-components";

export const BioContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 100px auto 0;
  margin-top: 0px;
  gap: 20px;
  border-radius: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: left;
    padding-top: 0px;
  }
`;

export const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Name = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

export const Subtitle = styled.h2`
  font-size: 1.3rem;
  color: #666;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

export const Description = styled.p`
  line-height: 1.6;
  color: #444;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

export const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #333;
  padding: 4px 8px;
  border-radius: 20px;
  width: fit-content;
`;

export const Indicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4caf50;
  margin-right: 8px;
  animation: ${pulseAnimation} 2s infinite;
`;

export const IndicatorText = styled.span`
  color: #fff;
  font-size: 14px;
`;

export const AvailabilityIndicatorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  object-position: 100% 70%;
  aspect-ratio: 1 / 1;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
