import styled from "styled-components";

export const EmailLink = styled.a`
  margin-top: 20px;
  margin-right: 15px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #333;
  border: 2px solid black;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    background-color: #f4f4f4;
    color: #333;
    border-color: black;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    white-space: nowrap;
  }
`;

export const Link = styled.a`
  margin-top: 20px;
  margin-right: 15px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #0077b5;
  border: 2px solid #0077b5;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    background-color: #0077b5;
    color: #fff;
    border-color: #0077b5;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    white-space: nowrap;
  }
`;

export const GitHubLink = styled.a`
  margin-top: 20px;
  padding: 10px 15px;
  font-size: 1rem;
  color: #333;
  border: 2px solid #333;
  border-radius: 20px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  text-decoration: none;

  &:hover {
    background-color: #333;
    color: #fff;
    border-color: #333;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    white-space: nowrap;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  flex-wrap: nowrap;

  @media (max-width: 768px) {
    padding: 5px;
    justify-content: flex-start;
    overflow-x: auto;
  }
`;
