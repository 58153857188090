import React from "react";

import { Link, GitHubLink, EmailLink, HeaderContainer } from "./Header-Styles";

export function Header() {
  const handleCopyEmail = () => {
    try {
      navigator.clipboard.writeText("ahmedahassan1@outlook.com");
      alert("Email copied to clipboard!");
    } catch (err) {
      const textArea = document.createElement("textarea");
      textArea.value = "ahmedahassan1@outlook.com";
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      alert("Email copied to clipboard!");
    }
  };

  const linkedInUrl = "https://www.linkedin.com/in/ahmedahassan1";
  const gitHubUrl = "https://github.com/ec20468";
  return (
    <HeaderContainer>
      <EmailLink onClick={handleCopyEmail}>Email</EmailLink>
      <Link as="a" href={linkedInUrl} target="_blank" rel="noopener noreferrer">
        LinkedIn
      </Link>
      <GitHubLink
        as="a"
        href={gitHubUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        Github
      </GitHubLink>
    </HeaderContainer>
  );
}
