import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 1.2;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  h2 {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 15px;
    font-weight: semibold;
    line-height: 1.3;

    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }

  p {
    font-size: 1rem;
    color: #444;
    line-height: 1.6;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
`;

export const theme = {
  colors: {
    primary: "#3498db",
    secondary: "#2ecc71",
    text: {
      heading: "#333",
      subHeading: "#666",
      body: "#444",
    },
  },
  typography: {
    fontFamily: "'Inter', sans-serif", // You can change this to your preferred font
    sizes: {
      h1: "2.5rem",
      h2: "1.2rem",
      body: "1rem",
    },
  },
};
