import React from "react";

import PFP from "../../Assets/pfp.png";
import {
  BioContainer,
  TextContainer,
  ProfileImage,
  Name,
  Subtitle,
  Description,
  AvailabilityIndicatorWrapper,
} from "./Bio-styles";
import AvailabilityIndicator from "./AvailabilityIndicator";

export default function Bio() {
  return (
    <BioContainer>
      <TextContainer>
        <Name>Ahmed Hassan</Name>
        <AvailabilityIndicatorWrapper>
          <AvailabilityIndicator />
        </AvailabilityIndicatorWrapper>

        <Subtitle>Computer Science Graduate & Junior Developer</Subtitle>
        <Description>
          A results-driven computer science graduate, striving to make an
          impact.
        </Description>
      </TextContainer>
      <ProfileImage src={PFP} alt="Ahmed Hassan" />
    </BioContainer>
  );
}
