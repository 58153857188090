import React from "react";
import styled, { keyframes } from "styled-components";
import {
  pulseAnimation,
  IndicatorContainer,
  Indicator,
  IndicatorText,
} from "./Bio-styles";

const AvailabilityIndicator = () => {
  return (
    <IndicatorContainer>
      <Indicator />
      <IndicatorText>Available for work</IndicatorText>
    </IndicatorContainer>
  );
};

export default AvailabilityIndicator;
